<!-- Categories GRID -->
<ng-container
  *ngIf="
    !(categoriesRepo.categories$ | async)?.isLoading &&
    !(servicesRepo.services$ | async)?.isLoading &&
    (servicesRepo.services$ | async)?.data &&
    type === 'grid'
  "
>
  <section id="categories" class="ion-padding-horizontal">
    @if (showHeader) {
      <header>
        <h5 class="ion-no-margin">
          {{ "domain.explore.components.categories.title" | transloco }}
        </h5>
        <ion-button
          size="small"
          fill="clear"
          (click)="navigateToAllServicesPage()"
          >{{
            "domain.explore.components.categories.button.seeAllServices"
              | transloco
          }}</ion-button
        >
      </header>
    }

    <!-- Switch home or city -->
    <!-- <section class="ion-padding-vertical">
      <ion-segment
        mode="ios"
        value="home"
        (ionChange)="onSegmentChanged($event)"
        [(ngModel)]="providedAt"
        [disabled]="
          (categoriesRepo.categories$ | async)?.isLoading ||
          (servicesRepo.services$ | async)?.isLoading ||
          isProcessing
        "
      >
        <ion-segment-button value="home">
          <ion-label>
            <ion-icon name="home"></ion-icon>
            <span>{{ "shared.label.atHome" | transloco }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="city">
          <ion-label>
            <ion-icon name="location-pin"></ion-icon>
            <span>{{ "shared.label.atCity" | transloco }}</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </section> -->
    <!-- ..// Switch home or city -->

    <!-- Categories -->

    <div
      id="categories-list"
      *ngIf="categoriesRepo.activeCategories$ | async as categories"
    >
      @for (category of categories; track category._id; let i = $index) {
        <ion-card
          color="light"
          button="true"
          (click)="handleCategoryClick(category)"
          [id]="getCategoryLabelWithoutSpaces(category.label)"
        >
          <ion-card-header>
            <ion-icon [name]="category.icon"></ion-icon>
          </ion-card-header>

          <ion-card-content>
            {{ category.label }}
          </ion-card-content>
        </ion-card>
      }
    </div>
  </section>
  <!-- ..// Categories -->
</ng-container>
<!-- ..// Categories GRID -->

<!-- Categories CAROUSEL -->
<ng-container
  *ngIf="
    !(categoriesRepo.categories$ | async)?.isLoading &&
    !(servicesRepo.services$ | async)?.isLoading &&
    (servicesRepo.services$ | async)?.data &&
    type === 'carousel'
  "
>
  <section id="categories" class="ion-padding-horizontal">
    @if (showHeader) {
      <header class="ion-margin-bottom">
        <h5 class="ion-no-margin">
          {{ "domain.explore.components.categories.title" | transloco }}
        </h5>
        <ion-button
          id="see-all-services"
          size="small"
          fill="clear"
          (click)="navigateToAllServicesPage()"
          >{{ "shared.label.seeAll" | transloco }}</ion-button
        >
      </header>
    }

    <!-- <section class="ion-padding-vertical">
      <ion-segment
        mode="ios"
        value="home"
        (ionChange)="onSegmentChanged($event)"
        [(ngModel)]="providedAt"
        [disabled]="
          (categoriesRepo.categories$ | async)?.isLoading ||
          (servicesRepo.services$ | async)?.isLoading ||
          isProcessing
        "
      >
        <ion-segment-button value="home">
          <ion-label>
            <ion-icon name="home"></ion-icon>
            <span>{{ "shared.label.atHome" | transloco }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="city">
          <ion-label>
            <ion-icon name="location-pin"></ion-icon>
            <span>{{ "shared.label.atCity" | transloco }}</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </section> -->

    <!-- Switch home or city -->
    <!--     <section class="ion-padding-vertical">
      <ion-segment mode="ios" value="home">
        <ion-segment-button value="home">
          <ion-label>
            <ion-icon name="home"></ion-icon>
            <span>{{ 'domain.explore.components.categories.atHome' | transloco }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="city">
          <ion-label>
            <ion-icon name="location-pin"></ion-icon>
            <span>{{ 'domain.explore.components.categories.atCity' | transloco }}</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </section> -->
    <!-- ..// Switch home or city -->

    <!-- Categories -->
    <div
      id="categories-carousel"
      *ngIf="categoriesRepo.activeCategories$ | async as categories"
    >
      <swiper-container
        slides-per-view="4"
        speed="500"
        space-between="10"
        pagination="false"
      >
        @for (category of categories; track category._id) {
          <swiper-slide class="slide">
            <ion-card button="true" (click)="handleCategoryClick(category)">
              <ion-card-header>
                <ion-card-title
                  ><ion-icon [name]="category.icon"></ion-icon
                ></ion-card-title>
              </ion-card-header>

              <ion-card-content> {{ category.label }} </ion-card-content>
            </ion-card>
          </swiper-slide>
        }
      </swiper-container>
    </div>
  </section>
  <!-- ..// Categories -->
</ng-container>
<!-- ..// Categories CAROUSEL -->

<!-- Categories skeleton -->
<ng-container
  *ngIf="
    (categoriesRepo.categories$ | async)?.isLoading ||
    (servicesRepo.services$ | async)?.isLoading
  "
>
  <section id="categories" class="ion-padding-horizontal">
    @if (showHeader) {
      <header class="ion-margin-bottom">
        <h5 class="ion-no-margin">
          {{ "domain.explore.components.categories.title" | transloco }}
        </h5>
      </header>
    }

    <!-- Switch home or city -->
    <!-- <section class="ion-padding-vertical">
      <ion-segment
        mode="ios"
        value="home"
        (ionChange)="onSegmentChanged($event)"
        [(ngModel)]="providedAt"
        [disabled]="
          (categoriesRepo.categories$ | async)?.isLoading ||
          (servicesRepo.services$ | async)?.isLoading ||
          isProcessing
        "
      >
        <ion-segment-button value="home">
          <ion-label>
            <ion-icon name="home"></ion-icon>
            <span>{{ "shared.label.atHome" | transloco }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="city">
          <ion-label>
            <ion-icon name="location-pin"></ion-icon>
            <span>{{ "shared.label.atCity" | transloco }}</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </section> -->
    <!-- ..// Switch home or city -->

    <div id="categories-list">
      @for (category of [1, 2, 3, 4, 5]; track category) {
        <ion-card
          color="light"
          style="min-height: 110px; display: flex; flex-direction: column"
        >
          <ion-card-header>
            <ion-skeleton-text
              [animated]="true"
              style="width: 40px; height: 40px"
            ></ion-skeleton-text>
          </ion-card-header>

          <ion-card-content>
            <ion-skeleton-text
              [animated]="true"
              style="width: 90%"
            ></ion-skeleton-text>
          </ion-card-content>
        </ion-card>
      }
    </div>
  </section>
</ng-container>
<!-- ..// Categories skeleton -->

<!-- No services available -->
<section
  id="no-services-available"
  class="ion-padding"
  *ngIf="
    !(categoriesRepo.categories$ | async)?.isLoading &&
    !(servicesRepo.services$ | async)?.isLoading &&
    !(categoriesRepo.categories$ | async)?.data?.length
  "
>
  <ng-lottie width="200px" height="200px" [options]="emptyOptions" />
  <h2>
    {{ "domain.explore.components.categories.noServices.title" | transloco }}
  </h2>
  <ion-text color="medium">
    <p>
      {{
        "domain.explore.components.categories.noServices.message" | transloco
      }}
    </p>
  </ion-text>
</section>
<!-- ..// No services available -->
