import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplyPromoCodeModel,
  LocationModel,
  PostGetSlotsModel,
  PostServiceRequestModel,
  PricingModel,
  ServiceModel,
  ServiceRequestModel,
  SlotModel,
} from '@app/core/models';
import { ServiceRequestsRepository } from '@app/store';
import { ServicesRepository } from '@app/store/services.repository';
import { trackRequestResult } from '@ngneat/elf-requests';
import { format } from 'date-fns';
import { Observable, of, tap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { environment } from 'src/environments/environment';

interface SearchLocationsParams {
  serviceId: string;
  lat: number;
  lng: number;
  dateFrom: string;
  dateTo: string;
  quantity: number;
}

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable()
export class ServiceService {
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _servicesRepo: ServicesRepository,
    private _serviceRequestsRepo: ServiceRequestsRepository,
  ) {}

  /**
   * Retrieves the services associated with a property.
   * @param propertyId - The ID of the property to retrieve services for.
   * @returns An Observable that emits an array of services.
   */
  getAllByProperty(
    propertyId: string,
    bookingId: string,
  ): Observable<ServiceModel[]> {
    return fromFetch<ServiceModel[]>(
      `${environment.api.serverUrl}/properties/${propertyId}/services?bookingId=${bookingId}`,
      {
        selector: response => response.json(),
      },
    ).pipe(
      tap(services => {
        this._servicesRepo.setServices(services);
      }),
      trackRequestResult(['services'], { skipCache: true }),
    );
  }

  applyPromoCode(data: ApplyPromoCodeModel): Observable<PricingModel> {
    return this._http.post<PricingModel>(
      `${environment.api.serverUrl}/services/${data.service}/apply-promo-code`,
      { ...data },
    );
  }

  /**
   * Retrieves the service slots from the server.
   * @returns An observable that emits the service slots.
   */
  getAvailableSlots(filters: PostGetSlotsModel): Observable<SlotModel[]> {
    let params = new HttpParams();

    params = params.set(
      'dateFrom',
      format(new Date(filters.dateFrom), 'yyyy-MM-dd'),
    );
    params = params.set(
      'dateTo',
      filters.dateTo
        ? format(new Date(filters.dateTo), 'yyyy-MM-dd')
        : format(new Date(filters.dateFrom), 'yyyy-MM-dd'),
    );
    params = params.set('quantity', filters.quantity);
    params = params.set('includeInfo', filters.includeInfo);
    try {
      return this._http.get<SlotModel[]>(
        `${environment.api.serverUrl}/services/${filters.service?._id}/slots`,
        { params },
      );
    } catch (error) {
      return of([]);
    }
  }

  /*   getService(
    categoryId: string,
    serviceId: string,
  ): Observable<ServiceModel | undefinservice-de> {
    return of(
      this.appStore
        .categoriesAndServices()
        .find(category => category._id === categoryId)
        ?.services.find(service => service._id === serviceId),
    );
  } */

  createServiceRequest(
    serviceId: string,
    serviceRequest: PostServiceRequestModel,
  ): Observable<ServiceRequestModel> {
    return this._http
      .post<ServiceRequestModel>(
        `${environment.api.serverUrl}/services/${serviceId}/service-request`,
        { ...serviceRequest },
      )
      .pipe(
        tap(serviceRequest => {
          this._serviceRequestsRepo.createServiceRequests(serviceRequest);
        }),
      );
  }

  cancelServiceRequest(
    serviceRequest: ServiceRequestModel,
  ): Observable<ServiceRequestModel> {
    return this._http
      .patch<ServiceRequestModel>(
        `${environment.api.serverUrl}/services/${serviceRequest.service._id}/service-request/${serviceRequest._id}/cancel`,
        {},
      )
      .pipe(
        tap(serviceRequest => {
          this._serviceRequestsRepo.updateServiceRequests([serviceRequest]);
        }),
      );
  }

  searchLocations(params: SearchLocationsParams): Observable<LocationModel[]> {
    let httpParams = new HttpParams()
      .set('lat', params.lat.toString())
      .set('lng', params.lng.toString())
      .set('dateFrom', params.dateFrom)
      .set('dateTo', params.dateTo)
      .set('quantity', params.quantity.toString());

    return this._http.get<LocationModel[]>(
      `${environment.api.serverUrl}/services/${params.serviceId}/locations`,
      { params: httpParams },
    );
  }

  requestPayment(serviceRequest: any): Observable<ServiceRequestModel> {
    return this._http.patch<ServiceRequestModel>(
      `${environment.api.serverUrl}/services/${serviceRequest.service._id}/service-request/${serviceRequest._id}/request-payment`,
      {
        paymentMethod: 'stripe',
      },
    );
  }
}
