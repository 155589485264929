import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private apiKey = environment.googleApiKey;

  constructor(private http: HttpClient) {}

  getDirections(
    origin: string,
    destination: string,
    travelMode: string = 'DRIVING',
  ) {
    const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&mode=${travelMode}&key=${this.apiKey}`;
    return this.http.get(url);
  }
}
