import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {
  CapacitorFirebaseFirestoreService,
  FirebaseAuthenticationService,
} from '@app/core/services';
import { SettingsPageService } from '@app/domains/menu/services';

import { firstValueFrom } from 'rxjs';

export const authGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const firebaseAuthenticationService = inject(FirebaseAuthenticationService);
  const settingsService = inject(SettingsPageService);
  const router = inject(Router);
  const firestoreService = inject(CapacitorFirebaseFirestoreService);

  const showOnBoarding = await firstValueFrom(settingsService.showOnBoarding$);
  if (showOnBoarding) {
    return router.createUrlTree(['/on-boarding']);
  }

  const user = await firebaseAuthenticationService.getCurrentUser();
  if (!user) {
    // Only store redirectUrl when user is not authenticated
    const url = state.url;
    if (!url.includes('/login') && !url.includes('/on-boarding')) {
      console.log('setting redirectUrl', url);
      firebaseAuthenticationService.redirectUrl = url;
    }
    return router.createUrlTree(['/login']);
  }

  // Add profile check
  const userDoc = await firestoreService.getDocument({
    reference: `users/${user.uid}`,
  });
  const userData = userDoc?.snapshot.data?.['profile'];

  const hasProfile = userData?.firstName || userData?.lastName;

  if (
    !hasProfile &&
    !state.url.includes('/complete-profile') &&
    !user.isAnonymous
  ) {
    return router.createUrlTree(['/complete-profile']);
  }

  return true;
};
