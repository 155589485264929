import { Pipe, PipeTransform } from '@angular/core';
import { BookingRepository } from '@app/store';

export interface TimeRange {
  rangeName: 'morning' | 'afternoon' | 'evening' | 'night';
  start: Date;
  end: Date;
}

import { toZonedTime } from 'date-fns-tz';
@Pipe({
  name: 'filterTimes',
  standalone: true,
})
export class FilterTimesPipe implements PipeTransform {
  constructor(private bookingRepo: BookingRepository) {}

  transform<T>(
    input: T,
    timeRange: 'morning' | 'afternoon' | 'evening' | 'night',
  ): T;
  transform(
    input: Date[],
    timeRange: 'morning' | 'afternoon' | 'evening' | 'night',
  ): any[];
  transform(input: Date[], timeRange: any = ''): any {
    const booking = this.bookingRepo.getActiveBooking();
    const timezone = booking?.property?.location?.timezone;

    if (!timezone) {
      return input;
    }

    if (!input) {
      return [];
    }

    const now = toZonedTime(new Date(), timezone);

    const timeRanges: TimeRange[] = [
      {
        rangeName: 'morning',
        start: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          0,
          0,
          0,
        ),
        end: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          11,
          59,
          59,
        ),
      },
      {
        rangeName: 'afternoon',
        start: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          12,
          0,
          0,
        ),
        end: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          17,
          59,
          59,
        ),
      },
      {
        rangeName: 'evening',
        start: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          18,
          0,
          0,
        ),
        end: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          20,
          59,
          59,
        ),
      },
      {
        rangeName: 'night',
        start: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          21,
          0,
          0,
        ),
        end: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59,
        ),
      },
    ];

    function extractTimeInMinutes(date: Date): number {
      return date.getHours() * 60 + date.getMinutes();
    }

    function isTimeInRange(
      targetTime: Date,
      startTime: Date,
      endTime: Date,
    ): boolean {
      const targetMinutes = extractTimeInMinutes(targetTime);
      const startMinutes = extractTimeInMinutes(startTime);
      const endMinutes = extractTimeInMinutes(endTime);

      if (startMinutes <= endMinutes) {
        return startMinutes <= targetMinutes && targetMinutes <= endMinutes;
      } else {
        // Handle time range crossing midnight
        return targetMinutes >= startMinutes || targetMinutes <= endMinutes;
      }
    }

    return input.filter(date => {
      const slotDate = new Date(date);
      const slotDateWithTimezone = toZonedTime(slotDate, timezone);
      const slotRange = timeRanges.find(range => range.rangeName === timeRange);

      if (slotRange) {
        return isTimeInRange(
          slotDateWithTimezone,
          slotRange.start,
          slotRange.end,
        );
      } else {
        return false;
      }
    });
  }
}
