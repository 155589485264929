import { AsyncPipe, CommonModule, CurrencyPipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CategoriesComponent } from './components/categories/categories.component';
import { DividerComponent } from './components/divider/divider.component';
import { ParallaxHeaderComponent } from './components/parallax-header/parallax-header.component';
import {
  ConvertToNumberPipe,
  LetDirective,
  ParallaxDirective,
} from './directives';
import { CancelationPoliciesDetailsComponent } from './modals/cancelation-policies-details/cancelation-policies-details.component';
import { PropertyTimezonePipe } from './pipes/property-tz.pipe';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  NgIf,
  AsyncPipe,
  LetDirective,
  DividerComponent,
  ConvertToNumberPipe,
  ParallaxDirective,
  ParallaxHeaderComponent,
  CancelationPoliciesDetailsComponent,
  CurrencyPipe,
  PropertyTimezonePipe,
  CategoriesComponent,
  PropertyTimezonePipe,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class SharedModule {}
