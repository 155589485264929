import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserBillingAddressModel, UserModel, UserPreferencesModel } from '@app/core/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {
  constructor(private _http: HttpClient) {}

  upsertStripeCustomer(data: UserBillingAddressModel): Observable<UserModel> {
    return this._http.put<UserModel>(
      `${environment.api.serverUrl}/users/stripe/customer`,
      { address: data },
    );
  }

  getStripeCustomerBillingAddress(): Observable<UserBillingAddressModel> {
    return this._http.get<UserBillingAddressModel>(
      `${environment.api.serverUrl}/users/stripe/customer/billing-address`,
    );
  }

  updateUserPreferences(preferences: UserPreferencesModel, uid: string): Observable<UserModel> {
    return this._http.put<UserModel>(
      `${environment.api.serverUrl}/users/preferences/${uid}`,
      preferences
    );
    
  }
}
